import GenericCard from '@/lib/ui/generic-card.tsx';
import { useGetTotalRevenue } from '@/app/app/dashboard/store/query/dashboard.query.tsx';
import Loading from '@/lib/ui/loading.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { cn, formatMoney } from '@/lib/utils.ts';

export default function TotalRevenue() {
  const { t } = useTranslation();
  const { data, loading } = useGetTotalRevenue();

  const diff = data?.totals?.diff;

  return (
    <GenericCard
      variant="secondary"
      title={`${t('total_revenue')}`}
      className="w-full"
      classNameBody="text-4xl"
      footer={
        <span className="font-light">
          <span
            className={cn('font-medium', {
              'text-danger': (diff || 0) < 0,
              'text-success': (diff || 0) > 0
            })}
          >
            {!loading && `${formatMoney(diff, { withSign: true })} `}
          </span>
          {t('respect_before_period')}
        </span>
      }
      subTitle={
        loading ? (
          <Loading className="min-h-[auto]" />
        ) : (
          <span className="flex flex-row gap-1 items-end">
            {formatMoney(data?.totals?.current)}
            <span className="text-lg font-light text-default-500">USD</span>
          </span>
        )
      }
    />
  );
}
