import { EllipsisVertical, Pencil, Plus, Trash } from 'lucide-react';
import { Button } from '@nextui-org/react';

import { useGetPlans } from '@/app/app/settings/store/query/plans.query.ts';
import TabContainer from '@/app/app/settings/components/TabContainer';
import PlanCard from '@/app/app/settings/components/Tabs/PlansTab/PlanCard.tsx';
import NewOrUpdatePlanModal from '@/app/app/settings/components/Tabs/PlansTab/NewOrUpdatePlanModal.tsx';
import useToggle from '@/hooks/useToggle.ts';
import EmptyState from '@/lib/ui/empty-state.tsx';
import useSelectEditItem from '@/hooks/useSelectEditItem.ts';
import { PlanFragmentFragment } from '@/__generated__/graphql.ts';
import Grid from '@/lib/ui/grid';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Tabs from '@/lib/ui/tabs.tsx';
import { ReactNode, useState } from 'react';
import { Table } from '@/lib/ui/table.tsx';
import { useGetSessionsCategories } from '@/app/app/settings/store/query/session_categories.query.ts';
import NewOrUpdateCategoryModal from '@/app/app/settings/components/Tabs/PlansTab/NewOrUpdateCategoryModal.tsx';
import Dropdown from '@/lib/ui/dropdown.tsx';
import { useDialog } from '@/lib/ui/Dialog/Provider.tsx';
import { useDeleteSessionCategory } from '@/app/app/settings/store/mutation/category.mutation.ts';
import StatusColor from '@/lib/ui/status-color.tsx';

enum TabEnum {
  plans = 'plans',
  categories = 'categories'
}

export default function PlansTab() {
  const { t } = useTranslation();
  const dialog = useDialog();
  const [isOpen, openActions] = useToggle();
  const [tab, setTab] = useState<TabEnum>(TabEnum.categories);
  const [editItem, onSetEdit] =
    useSelectEditItem<PlanFragmentFragment>(openActions);

  const isPlan = tab === TabEnum.plans;

  const [isOpenCat, openActionsCat] = useToggle();
  const [editItemCat, onSetEditCat] = useSelectEditItem<{
    id: string;
    name: string;
  }>(openActionsCat);

  const { data, loading } = useGetPlans(isPlan ? 1 : 0);
  const deleteCategoryQuery = useDeleteSessionCategory();
  const categoriesQuery = useGetSessionsCategories(isPlan ? 0 : 1);

  const onDeleteCategory = async (item: any) => {
    dialog.confirm({
      recordName: item.name,
      title: t('settings.delete_category_session'),
      actions: {
        onConfirm: () => {
          return deleteCategoryQuery.onDeleteCategory(item.id);
        }
      }
    });
  };

  const tabs: Record<TabEnum, ReactNode> = {
    plans: (
      <>
        <Grid gutter={[20, 20]}>
          {data?.plans?.map?.((p) => {
            const plan = p as PlanFragmentFragment;
            return (
              <Grid.Item xs={24} sm={12} md={8} lg={6}>
                <PlanCard
                  {...plan}
                  key={plan.id}
                  onEdit={() => onSetEdit(plan, true)}
                />
              </Grid.Item>
            );
          })}
        </Grid>

        {!data?.plans?.length && <EmptyState title={t('empty')} />}
      </>
    ),
    categories: (
      <div>
        <Table<any>
          isLoading={categoriesQuery.loading}
          rows={categoriesQuery?.data?.sessionCategories || []}
          columns={[
            {
              field: 'name',
              headerName: t('name')
            },
            {
              field: 'plansCount',
              headerName: t('settings.class_offered')
            },
            {
              field: 'status',
              headerName: t('status'),
              renderCell({ row }) {
                return (
                  <StatusColor
                    label={row.status ? t('active') : t('inactive')}
                    severity={row?.status === 'active' ? 'success' : 'danger'}
                  />
                );
              }
            },
            {
              field: 'actions',
              headerName: '',
              width: 50,
              renderCell: ({ row }) => (
                <Dropdown
                  className="flex"
                  items={[
                    {
                      key: 'edit',
                      children: t('edit'),
                      onClick() {
                        onSetEditCat(row, true);
                      },
                      startContent: <Pencil size={16} />
                    },
                    {
                      key: 'delete',
                      children: t('delete'),
                      color: 'danger',
                      startContent: <Trash size={16} />,
                      onClick: () => onDeleteCategory(row)
                    }
                  ]}
                >
                  <Button
                    size="sm"
                    isIconOnly
                    variant="light"
                    endContent={<EllipsisVertical size={16} />}
                  />
                </Dropdown>
              )
            }
          ]}
        />
      </div>
    )
  };

  return (
    <TabContainer
      loading={loading}
      title={t(
        tab === TabEnum.plans ? 'settings.plans' : 'settings.categories'
      )}
      header={
        <Tabs
          selectedKey={tab}
          onSelectionChange={(tt) => setTab(tt as TabEnum)}
          items={[
            {
              key: TabEnum.categories,
              label: t('settings.categories')
            },
            {
              key: TabEnum.plans,
              label: t('settings.plans')
            }
          ]}
        />
      }
      action={
        <Button
          color="primary"
          endContent={<Plus />}
          onClick={() =>
            isPlan ? onSetEdit(null, true) : onSetEditCat(null, true)
          }
        >
          {t('add')}
        </Button>
      }
    >
      {tabs[tab || TabEnum.plans]}

      <div className="h-24" />

      <NewOrUpdatePlanModal
        open={isOpen}
        item={editItem}
        onClose={() => onSetEdit(null, false)}
      />

      <NewOrUpdateCategoryModal
        open={isOpenCat}
        item={editItemCat}
        onClose={() => onSetEditCat(null, false)}
      />
    </TabContainer>
  );
}
