import GenericCard from '@/lib/ui/generic-card.tsx';
import { useGetTotalCustomers } from '@/app/app/dashboard/store/query/dashboard.query.tsx';
import Loading from '@/lib/ui/loading.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import formatTotal from '@/app/app/dashboard/common/formatTotal.ts';
import { cn } from '@/lib/utils.ts';

export default function TotalActiveCustomers() {
  const { t } = useTranslation();
  const { loading, data } = useGetTotalCustomers();

  const diff = data?.totals?.diff;

  return (
    <GenericCard
      variant="secondary"
      title={t('total_customers')}
      classNameBody="flex items-center gap-2 text-4xl"
      className="w-full"
      footer={
        !loading && (
          <span className="font-light">
            <span
              className={cn('font-medium', {
                'text-danger': (diff || 0) < 0,
                'text-success': (diff || 0) > 0
              })}
            >
              {`${formatTotal(diff)} `}
            </span>

            {t('respect_before_period')}
          </span>
        )
      }
      subTitle={
        <>
          {loading ? (
            <Loading className="min-h-[auto]" />
          ) : (
            <span>{data?.totals?.current || 0}</span>
          )}
        </>
      }
    />
  );
}
