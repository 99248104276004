import { gql } from '@apollo/client';

export const CLASS_OFFERED_BASIC_FRAGMENT = gql`
  fragment ClassOfferedFragment on ClassOffered {
    id
    name
    short_summary
    image {
      id
      url
    }
    schedules {
      day
      time
      startTime
      endTime
    }
  }
`;

export const PLAN_BASIC_FRAGMENT = gql`
  fragment PlanFragment on Plan {
    id
    name
    points
    price
    duration
    status
    creditCount
    categories(where: { status: { equals: "active" } }) {
      id
      name
    }
    categoriesCount(where: { status: { equals: "active" } })
  }
`;

export const CREDIT_BASIC_FRAGMENT = gql`
  fragment CreditFragment on Credit {
    id
    creditUsed
    creditBuy
    openAt
    totalPrice
    expiredAt
    status
    subscriptionsCount
    paymentMethod
    plan {
      ...PlanFragment
    }
  }
`;

export const USER_BASIC_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    fullName
    state
    paymentPreference
    avatar {
      id
      url
    }
    devices {
      id
      token
      device_manufacturer
      device_model
      device_name
      device_platform
      device_system_version
    }
  }
`;

export const CLIENT_ASSOCIATED_BASIC_FRAGMENT = gql`
  fragment ClientsAssociatedFragment on ClientsAssociated {
    id
    user {
      ...UserFragment
    }
  }
`;

export const BADGE_ITEM_BASIC_FRAGMENT = gql`
  fragment BadgeItemFragment on BadgeItem {
    id
    name
    color
    icon
    userCount
    badge {
      id
      name
    }
  }
`;

export const CLIENT_COMPLETE_FRAGMENT = gql`
  fragment ClientCompleteFragment on User {
    id
    email
    firstName
    lastName
    fullName
    state
    paymentPreference
    avatar {
      id
      url
    }
    badges {
      ...BadgeItemFragment
    }
    lastCredit: credits(
      where: { status: { equals: "valid" } }
      orderBy: { createdAt: desc }
      take: 1
    ) {
      ...CreditFragment
    }
  }
`;

export const SESSION_FRAGMENT = gql`
  fragment SessionFragment on Session {
    id
    name
    description
    color
    limitQuote
    cost
    startedAt
    finishedAt
    status
    createdAt
    isBacklog
    subscriptionsCount
    subscriptions {
      id
      confirmedAt
      creditCost
      createdAt
      status
      user {
        id
        fullName
        avatar {
          id
          url
        }
      }
    }
    coach {
      id
      fullName
      avatar {
        id
        url
      }
    }
    rootEvent {
      id
      limitQuote
      cost
      startedAt
      finishedAt
      interval
      frequency
      daysRepeat
      finishType
      count
      finishedRepeatAt
    }
    categories(where: { status: { equals: "active" } }) {
      id
      name
      status
    }
  }
`;

export const SESSION_BOARD_FRAGMENT = gql`
  fragment SessionBoardFragment on SessionByBackoffice {
    id
    name
    description
    color
    limitQuote
    cost
    startedAt
    finishedAt
    status
    createdAt
    isBacklog
    subscriptionsCount
    subscriptions {
      id
      confirmedAt
      creditCost
      createdAt
      status
      user {
        id
        fullName
        avatar {
          id
          url
        }
      }
    }
    coach {
      id
      fullName
      avatar {
        id
        url
      }
    }
    rootEvent {
      id
      limitQuote
      cost
      startedAt
      finishedAt
      interval
      frequency
      daysRepeat
      finishType
      count
      finishedRepeatAt
    }
    categories {
      id
      name
      status
    }
  }
`;

export const COACH_BASIC_FRAGMENT = gql`
  fragment CoachFragment on Coach {
    id
    email
    firstName
    lastName
    fullName
    status
    avatar {
      id
      url
    }
  }
`;

export const BADGE_BASIC_FRAGMENT = gql`
  fragment BadgeFragment on Badge {
    id
    name
    items {
      id
      name
      color
      icon
      userCount
    }
  }
`;

export const USER_BACKOFFICE_FRAGMENT = gql`
  fragment UserBackofficeFragment on UserBackoffice {
    id
    isAdmin
    isBlocked
    isOwner
    user {
      ...UserFragment
    }
  }
`;
