import { toast } from 'sonner';
import { useMutation } from '@apollo/client';
import { useAuth } from '@/app/auth/_context';
import {
  POST_CREATE_SESSION_CATEGORY,
  POST_DELETE_SESSION_CATEGORY,
  POST_UPDATE_SESSION_CATEGORY
} from '@/app/app/settings/store/mutation/graphql/mutations.graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export function useCreateSessionCategory() {
  const { activeSpace } = useAuth();
  const [mutation, actionMutation] = useMutation(POST_CREATE_SESSION_CATEGORY, {
    update(cache, { data }) {
      const createItem = data?.createSessionCategory;
      if (createItem) {
        cache.modify({
          fields: {
            sessionCategories(existingItems = []) {
              return [...existingItems, createItem];
            }
          }
        });
      }
    }
  });

  const onCreateCategory = async (variables: { name: string }) => {
    try {
      await mutation({
        variables: {
          ...variables,
          spaceId: activeSpace?.space?.id as string
        }
      });
      toast.success('Category created');
    } catch (error) {
      toast.error((error as Error)?.message || 'Error creating category');
      console.error(error);
      throw error;
    }
  };

  return { onCreateCategory, ...actionMutation };
}

export function useUpdateSessionCategory() {
  const [mutation, actionMutation] = useMutation(POST_UPDATE_SESSION_CATEGORY);

  const onUpdateCategory = async (
    id: string,
    data: { name?: string; status?: string }
  ) => {
    try {
      await mutation({
        variables: {
          id,
          data: {
            name: data?.name
          }
        }
      });
      toast.success('Session Category updated');
    } catch (error) {
      toast.error(
        (error as Error)?.message || 'Error updated session category'
      );
      console.error(error);
      throw error;
    }
  };

  return { onUpdateCategory, ...actionMutation };
}

export function useDeleteSessionCategory() {
  const { t } = useTranslation();

  const [mutation, actionMutation] = useMutation(POST_DELETE_SESSION_CATEGORY, {
    update(cache, { data }) {
      const deleteItem = data?.deleteSessionCategory;
      if (deleteItem) {
        cache.modify({
          fields: {
            sessionCategories(existingItems = [], { readField }) {
              return existingItems.filter(
                (commentRef: any) =>
                  readField('id', commentRef) !== deleteItem.id
              );
            }
          }
        });
      }
    }
  });

  const onDeleteCategory = async (id: string) => {
    try {
      await mutation({
        variables: {
          id
        }
      });
      toast.success(t('alert_successfully'));
    } catch (error) {
      console.error(error);
      toast.error(
        (error as Error)?.message || t('errors_message.unexpected_error')
      );
      throw error;
    }
  };

  return { onDeleteCategory, ...actionMutation };
}
