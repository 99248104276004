export function getLocalStorage<
  T extends Record<string, any> = Record<string, any>
>(key: string): T | null {
  const value = window?.localStorage?.getItem(key);

  try {
    return JSON.parse(value || '{}');
  } catch (e) {
    return null;
  }
}

export function setLocalStorage(
  key: string,
  value: Record<string, any> | string | null | undefined
) {
  try {
    if (typeof value === 'string') {
      return window?.localStorage?.setItem(key, value);
    }

    return window?.localStorage?.setItem(key, JSON.stringify(value));
  } catch (e) {
    return null;
  }
}
