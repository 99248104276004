import { gql } from '@/__generated__/gql.ts';

export const CREATE_CLIENT_QUERY = gql(`
  mutation CreateClient($data: UserCreateInput!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
`);

export const UPDATE_CLIENT_QUERY = gql(`
  mutation UpdateClient($clientId: ID!, $data: UserUpdateInput!) {
    updateUser(where: { id: $clientId }, data: $data) {
      ...UserFragment
    }
  }
`);

export const CREATE_PAYMENT_MUTATION = gql(`
  mutation CreatePayment($data: PaymentCreateInput!) {
    createPayment(data: $data) {
      id
      status
    }
  }
`);

export const CREATE_SUBSCRIPTION_QUERY = gql(`
  mutation CreateCredit($data: CreditCreateInput!) {
    createCredit(data: $data) {
      ...CreditFragment
    }
  }
`);

export const UPDATE_SUBSCRIPTION_QUERY = gql(`
  mutation UpdateCredit($creditId: ID!, $data: CreditUpdateInput!) {
    updateCredit(where: { id: $creditId }, data: $data) {
      ...CreditFragment
    }
  }
`);
