import { useMutation } from '@apollo/client';
import { useAuth } from '@/app/auth/_context';
import { POST_UPDATE_CLASS } from '@/app/app/settings/store/mutation/graphql/mutations.graphql.ts';
import { ClassOfferedCreateInput } from '@/__generated__/graphql.ts';
import { toast } from 'sonner';
import { useTranslation } from '@/hooks/useTranslation.ts';

export function useCreateClass() {
  const { activeSpace, onEditSpace, loadingUpdateSpace } = useAuth();

  const onCreateClass = async (variables: ClassOfferedCreateInput) => {
    return onEditSpace({
      spaceId: activeSpace?.space?.id ?? '',
      data: {
        classOffered: {
          create: [variables]
        }
      }
    });
  };

  return { onCreateClass, loading: loadingUpdateSpace };
}

export function useUpdateClass() {
  const [mutation, actionMutation] = useMutation(POST_UPDATE_CLASS);
  const { t } = useTranslation();

  const onUpdateClass = async (
    id: string,
    data: Partial<ClassOfferedCreateInput>
  ) => {
    try {
      await mutation({
        variables: {
          id,
          data
        }
      });
      toast.success(t('alert_successfully'));
      window?.location?.reload?.();
    } catch (error) {
      console.error(error);
      toast.error(
        (error as Error)?.message || t('errors_message.unexpected_error')
      );
      throw error;
    }
  };

  return { onUpdateClass, ...actionMutation };
}
