import { Button, Chip } from '@nextui-org/react';
import { MoreVertical, PencilLine, Plus, Trash2 } from 'lucide-react';
import Text from '@/lib/ui/text.tsx';
import { Table } from '@/lib/ui/table.tsx';
import Dropdown from '@/lib/ui/dropdown.tsx';
import {
  BadgeFragmentFragment,
  BadgeItemFragmentFragment
} from '@/__generated__/graphql.ts';
import NewBadgeItemModal from '@/app/app/settings/components/Tabs/BadgeTab/NewBadgeItemModal.tsx';
import useSelectEditItem from '@/hooks/useSelectEditItem.ts';
import useToggle from '@/hooks/useToggle.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useDeleteBadgeItem } from '@/app/app/settings/store/mutation/badge.mutation.ts';
import { useDialog } from '@/lib/ui/Dialog/Provider.tsx';

export default function BadgeItemComponent({
  badge,
  loading,
  onDelete,
  onEdit
}: {
  loading: boolean;
  badge: BadgeFragmentFragment;
  onDelete: (badge: BadgeFragmentFragment) => void;
  onEdit: (badge: BadgeFragmentFragment) => void;
}) {
  const { t } = useTranslation();
  const dialog = useDialog();
  const [isNewModal, actionsNewModal] = useToggle();
  const [editChip, setEditChip] =
    useSelectEditItem<BadgeItemFragmentFragment>();

  const { onDeleteBadgeItem } = useDeleteBadgeItem();

  return (
    <div className="mb-12">
      <div className="flex items-center mb-4 gap-2">
        <Text className="font-bold">{badge.name}</Text>

        <Button
          variant="light"
          className="w-[40px] h-[30px] min-w-fit px-2"
          startContent={<PencilLine size="18px" />}
          onClick={() => {
            onEdit(badge);
          }}
        />
        <Button
          color="danger"
          variant="light"
          startContent={<Trash2 size="18px" />}
          className="w-[40px] h-[30px] bg-transparent min-w-fit px-2"
          onClick={() => onDelete(badge)}
        />
        <Button
          color="primary"
          variant="light"
          startContent={<Plus />}
          className="w-[40px] h-[30px] bg-transparent min-w-fit px-2"
          onClick={actionsNewModal.onVisible}
        />
      </div>
      <Table
        isLoading={loading}
        rows={badge?.items ?? []}
        columns={[
          {
            field: 'name',
            headerName: t('name')
          },
          {
            field: 'color',
            headerName: t('color'),
            renderCell({ row }) {
              return (
                <Chip
                  variant="flat"
                  classNames={{
                    content: 'flex items-center justify-center gap-2'
                  }}
                >
                  <span
                    className="w-5 h-5 rounded"
                    style={{
                      backgroundColor: row?.color || ''
                    }}
                  />
                  {row?.color}
                </Chip>
              );
            }
          },
          {
            field: 'icon',
            headerName: t('icon'),
            renderCell({ row }) {
              if (!row?.icon) return '-';
              return <span className="text-2xl">{row?.icon}</span>;
            }
          },
          {
            field: 'userCount',
            headerName: t('users'),
            renderCell({ row }) {
              return row?.userCount || '0';
            }
          },
          {
            field: 'actions',
            headerName: '',
            width: 50,
            renderCell({ row }) {
              return (
                <Dropdown
                  items={[
                    {
                      sectionName: t('actions'),
                      items: [
                        {
                          key: 'edit-chip',
                          showDivider: true,
                          startContent: (
                            <PencilLine
                              size="16px"
                              className="text-default-500"
                            />
                          ),
                          children: t('edit'),
                          onClick: () => setEditChip(row)
                        },
                        {
                          key: 'delete-chip',
                          startContent: (
                            <Trash2 size="16px" className="text-default-500" />
                          ),
                          children: t('delete'),
                          onClick: async () => {
                            dialog.confirm({
                              title: t('delete_modal_title'),
                              recordName: row?.name,
                              actions: {
                                onConfirm: async () => {
                                  await onDeleteBadgeItem({
                                    badgeId: row?.id as string
                                  });
                                }
                              }
                            });
                          }
                        }
                      ]
                    }
                  ]}
                >
                  <Button
                    variant="light"
                    disabled={loading}
                    className="h-8 w-8 p-0 text-current"
                  >
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </Dropdown>
              );
            }
          }
        ]}
      />

      <NewBadgeItemModal
        item={editChip}
        badgeId={badge.id}
        open={isNewModal || !!editChip}
        onClose={() => {
          actionsNewModal.onHidden();
          setEditChip(null);
        }}
      />
    </div>
  );
}
