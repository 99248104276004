import { Card, CardBody, CardHeader } from '@nextui-org/react';

import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import LinearChart from '@/lib/ui/charts/linear-chart.tsx';
import { TitleWithFilter } from '@/app/app/dashboard/components/TitleWithFilter.tsx';
import { useGetActivityByCoach } from '@/app/app/dashboard/store/query/dashboard.query.tsx';

export function ActivityByCoachGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { activity, loading } = useGetActivityByCoach();

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <TitleWithFilter>{t('activity.title_by_coach')}</TitleWithFilter>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <LinearChart loading={loading} name={t('classes')} {...activity} />
      </CardBody>
    </Card>
  );
}
