import { Button } from '@nextui-org/react';
import { Plus } from 'lucide-react';

import TabContainer from '../../TabContainer';
import NewBadgeModal from './NewBadgeModal.tsx';
import useSelectEditItem from '@/hooks/useSelectEditItem.ts';
import { BadgeFragmentFragment } from '@/__generated__/graphql.ts';
import { useGetBadges } from '@/app/app/settings/store/query/badge.query.ts';
import BadgeItemComponent from '@/app/app/settings/components/Tabs/BadgeTab/BadgeItem.tsx';
import useToggle from '@/hooks/useToggle.ts';
import EmptyState from '@/lib/ui/empty-state.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useDialog } from '@/lib/ui/Dialog/Provider.tsx';
import { useDeleteBadge } from '@/app/app/settings/store/mutation/badge.mutation.ts';

export default function BadgeTab() {
  const { t } = useTranslation();
  const dialog = useDialog();
  const { data, loading } = useGetBadges();

  const [isNewModal, actionsNewModal] = useToggle();
  const [editChip, setEditChip] = useSelectEditItem<BadgeFragmentFragment>();

  const badges = (data?.badges ?? []) as BadgeFragmentFragment[];

  const { onDeleteBadge, loading: deleteLoading } = useDeleteBadge();

  const setDeleteChip = (badge: BadgeFragmentFragment) => {
    return dialog.confirm({
      title: t('delete_modal_title'),
      recordName: badge?.name,
      actions: {
        onConfirm: () => {
          return onDeleteBadge({
            badgeId: badge?.id as string
          });
        }
      }
    });
  };

  return (
    <TabContainer
      title={t('settings.tags')}
      loading={loading}
      action={
        <Button
          color="primary"
          endContent={<Plus />}
          onClick={actionsNewModal.onVisible}
        >
          {t('add')}
        </Button>
      }
    >
      {!badges.length && !loading && <EmptyState title={t('empty_table')} />}
      {badges.map((badge) => (
        <BadgeItemComponent
          key={badge.id}
          badge={badge}
          onEdit={setEditChip}
          onDelete={setDeleteChip}
          loading={loading || deleteLoading || !!editChip}
        />
      ))}

      <NewBadgeModal
        item={editChip}
        open={isNewModal || !!editChip}
        onClose={() => {
          actionsNewModal.onHidden();
          setEditChip(null);
        }}
      />
    </TabContainer>
  );
}
