import { toast } from 'sonner';
import { useMutation } from '@apollo/client';
import {
  NEW_USER_BACKOFFICE,
  UPDATE_USER_BACKOFFICE,
  UPDATE_USER_BASE
} from '@/app/app/settings/store/mutation/graphql/mutations.graphql.ts';
import { useAuth } from '@/app/auth/_context';
import { CreateNewUserBackofficeMutation } from '@/__generated__/graphql.ts';
import { UserBackOffice } from '@/app/app/settings/components/Tabs/AdminsTab/types.ts';
import { useUsersQuery } from '@/app/app/settings/store/query/users.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

const USER_EXIST_ERROR_NAME = 'User already exists';

export function useNewUserBackoffice() {
  const { data } = useUsersQuery();
  const { t } = useTranslation();

  const { activeSpace } = useAuth();
  const [mutation, actionMutation] = useMutation(NEW_USER_BACKOFFICE, {
    update(cache, { data }) {
      const { createUserBackoffice } = data as CreateNewUserBackofficeMutation;
      if (!createUserBackoffice) return;
      cache.modify({
        fields: {
          userBackoffices(existingUsersBackoffice = []) {
            return [...existingUsersBackoffice, createUserBackoffice];
          }
        }
      });
    }
  });

  const onCreateAdminUser = async (payload: UserBackOffice) => {
    const isExistUser = data?.userBackoffices?.find?.(
      (item: any) => item?.user?.email === payload?.user?.email
    );

    const userPayload = payload?.user?.id
      ? { connect: { id: payload?.user?.id } }
      : {
          create: {
            firstName: payload?.user.firstName,
            lastName: payload?.user.lastName,
            email: payload?.user.email
          }
        };

    try {
      if (isExistUser) {
        throw new Error(USER_EXIST_ERROR_NAME);
      }

      await mutation({
        variables: {
          data: {
            isAdmin: payload?.isAdmin,
            isOwner: payload?.isOwner,
            user: userPayload,
            space: {
              connect: {
                id: activeSpace?.space?.id as string
              }
            }
          }
        }
      });
      toast.success(t('alert_successfully'));
    } catch (error) {
      console.error(error);
      const e = error as Error;
      let description = '';

      if (e?.message === 'Prisma error') {
        description = t('the_user_already_exists_extend');
      } else if (e?.message == USER_EXIST_ERROR_NAME) {
        description = t('the_user_already_exists');
      }
      toast.error(
        (error as Error)?.message || t('errors_message.unexpected_error'),
        {
          description
        }
      );
      throw error;
    }
  };

  return { onCreateAdminUser, ...actionMutation };
}

export function useUpdateUserBackoffice() {
  const { t } = useTranslation();
  const [onUpdateUser, { loading }] = useMutation(UPDATE_USER_BASE);

  const [mutation, actionMutation] = useMutation(UPDATE_USER_BACKOFFICE);

  const onUpdateAdminUser = async (
    id: string,
    payload: Partial<UserBackOffice>
  ) => {
    try {
      if (payload?.user?.id) {
        await onUpdateUser({
          variables: {
            id: payload?.user?.id,
            data: {
              firstName: payload?.user.firstName,
              lastName: payload?.user.lastName,
              email: payload?.user.email
            }
          }
        });
      }

      await mutation({
        variables: {
          id,
          data: {
            isAdmin: payload?.isAdmin,
            isOwner: payload?.isOwner
          }
        }
      });
      toast.success(t('alert_successfully'));
    } catch (error) {
      console.error(error);
      toast.error(
        (error as Error)?.message || t('errors_message.unexpected_error')
      );
      throw error;
    }
  };

  return {
    onUpdateAdminUser,
    ...actionMutation,
    loading: actionMutation.loading || loading
  };
}
